import request from 'graphql-request';

import { ID } from '../../../../types';

import { FetchProductsSetByUserIdScopeType } from '../../productsSetsTypes';

import { GRAPHQL_API_URI } from '../../../../config';
import { productionConsole } from '../../../../utils/productionConsole';
import { getQueryName } from '../../../../utils/getQueryName';
import { generateUuid } from '../../../../utils/generateUuid';

interface FetchProductsSetByUserIdProps {
  query: string;
  input: FetchProductsSetByUserIdInput;
}

export interface FetchProductsSetByUserIdInput {
  userId: ID;
  scope?: FetchProductsSetByUserIdScopeType;
}

export function fetchProductsSetByUserId({
  query,
  input
}: FetchProductsSetByUserIdProps) {
  const { rawName, name } = getQueryName(query);

  productionConsole(
    'log',
    'fetchProductsSetByUserId',
    input,
    rawName,
    JSON.stringify(input)
  );

  const requestId = generateUuid();

  return request(
    `${GRAPHQL_API_URI}?queryName=${name}&requestId=${requestId}`,
    query,
    input,
    { requestId }
  );
}

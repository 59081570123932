import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { LocalForage } from '../../../../utils/LocalForage';

import { UserID } from '../../../users/usersTypes';

import { FetchProductsSetByUserIdScopeType } from '../../productsSetsTypes';

import {
  FetchProductsSetByUserIdError,
  FetchProductsSetByUserIdResponse,
  ProductsSetByUserIdItemOptimisticUpdateType
} from './types';

import { useUpdateProductsSetCache } from './hooks/useUpdateProductsSetCache';

import { fetchProductsSetByUserId } from '../../actions/fetchProductsSetByUserId';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { parseRequestError } from '../../../../utils/parseRequestError';

interface ProductsSetByUserIdOptions<ProductsSetByUserIdItemType> {
  cacheKey: string;
  query: string;
  userId: UserID;
  scope?: FetchProductsSetByUserIdScopeType;
  options?: {
    cacheTime?: number;
    onSuccess?: (
      data: FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType>
    ) => void;
  };
}

const itemKey = 'productsSetByUserId';

function useProductsSetByUserId<ProductsSetByUserIdItemType>({
  cacheKey,
  query,
  userId,
  scope,
  options = {}
}: ProductsSetByUserIdOptions<ProductsSetByUserIdItemType>) {
  const currentUser = useCurrentUser();

  const { data: placeholderData, isFetched: placeholderDataFetched } =
    useQuery<FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType> | null>(
      `${cacheKey}-placeholder`,
      () =>
        LocalForage.getItem<
          FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType>
        >(cacheKey)
    );

  const fullCacheKey = useMemo(() => {
    return [cacheKey, { userId, scope }];
  }, [cacheKey, userId, scope]);

  const { data, isFetched, isLoading, error, isPlaceholderData } = useQuery<
    FetchProductsSetByUserIdResponse<
      ProductsSetByUserIdItemType & ProductsSetByUserIdItemOptimisticUpdateType
    >,
    FetchProductsSetByUserIdError
  >(
    fullCacheKey,
    () =>
      fetchProductsSetByUserId({
        query,
        input: { userId, scope }
      }),
    {
      enabled: placeholderDataFetched,
      cacheTime: options.cacheTime,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        if (
          currentUser.get('id') === userId &&
          !data?.[itemKey]?.optimisticUpdate
        ) {
          LocalForage.setItem<
            FetchProductsSetByUserIdResponse<ProductsSetByUserIdItemType>
          >(cacheKey, data);
        }
      },
      placeholderData: () => {
        if (placeholderData && currentUser.get('id') === userId) {
          return placeholderData;
        }
      }
    }
  );

  const updateProductsSetCache = useUpdateProductsSetCache<
    ProductsSetByUserIdItemType & ProductsSetByUserIdItemOptimisticUpdateType
  >({
    fullCacheKey,
    itemKey
  });

  return {
    productsSet: data?.[itemKey] || null,
    productsSetError: parseRequestError(error),
    productsSetFetched: isFetched,
    productsSetLoading: isLoading,
    productsSetIsPlaceholderData: isPlaceholderData,
    updateProductsSetCache
  };
}

export default useProductsSetByUserId;
